import { authConstants, workerConstants, globalConstants } from '../constants';
import { companyConstants } from '../constants/companyConstants';
import { setItem, getItem, removeItem, mapCompanies } from '../helpers';

const loggedIn = !!getItem(globalConstants.LOCAL_STORAGE_USER);
const userAttributes = getItem(globalConstants.LOCAL_STORAGE_USER) && getItem(globalConstants.LOCAL_STORAGE_USER).userAttributes;
const token = loggedIn && getItem(globalConstants.LOCAL_STORAGE_USER).token;

export function authentication(
	state = {
		loggedIn: loggedIn,
		passwordReset: false,
		userAttributes: userAttributes,
		company: userAttributes?.company || {},
		token: token,
		authType: '',
		roleName: userAttributes && userAttributes.role && userAttributes.role.roleName
	},
	action = {}
) {
	switch (action.type) {
		case authConstants.LOGIN_REQUEST:
			return {
				...state,
				loggedIn: false,
				authType: ''
			};
		case authConstants.LOGIN_SUCCESS:
			const data = { ...action.result.data, companies: mapCompanies(action.result.data.companies) };
			setItem(globalConstants.LOCAL_STORAGE_USER, {
				token: action.result.token,
				expirationDate: action.result.tokenExpire * 1000,
				userAttributes: data
			});
			return {
				...state,
				userAttributes: data,
				roleName: data.role.roleName,
				token: action.result.token,
				loggedIn: true,
				authType: globalConstants.REGULAR_LOGIN
			};
		case authConstants.LOGIN_FAILURE:
			return {
				...state,
				loggedIn: false
			};
		case authConstants.LOGIN_GOOGLE_REQUEST:
			return {
				...state,
				googleLoginLoading: true,
				loggedIn: false,
				authType: ''
			};
		case authConstants.LOGIN_GOOGLE_SUCCESS:
			const googleLoginData = { ...action.result.data, companies: mapCompanies(action.result.data.companies) };
			setItem(globalConstants.LOCAL_STORAGE_USER, {
				token: action.result.token,
				expirationDate: action.result.tokenExpire * 1000,
				userAttributes: googleLoginData
			});
			return {
				...state,
				userAttributes: googleLoginData,
				roleName: googleLoginData.role.roleName,
				token: action.result.token,
				loggedIn: true,
				googleLoginLoading: false,
				authType: globalConstants.GOOGLE_LOGIN
			};
		case authConstants.LOGIN_GOOGLE_FAILURE:
			return {
				...state,
				loggedIn: false,
				googleLoginLoading: false
			};
		//appleLogin
		case authConstants.LOGIN_APPLE_REQUEST:
			return {
				...state,
				appleLoginLoading: true,
				loggedIn: false,
				authType: '',
				appleLoginFailed: false
			};
		case authConstants.LOGIN_APPLE_SUCCESS:
			const appleLoginData = { ...action.result.data, companies: mapCompanies(action.result.data.companies) };
			setItem(globalConstants.LOCAL_STORAGE_USER, {
				token: action.result.token,
				expirationDate: action.result.tokenExpire * 1000,
				userAttributes: appleLoginData
			});
			return {
				...state,
				userAttributes: appleLoginData,
				roleName: appleLoginData.role.roleName,
				token: action.result.token,
				loggedIn: true,
				appleLoginLoading: false,
				authType: globalConstants.REGULAR_LOGIN
			};
		case authConstants.LOGIN_APPLE_FAILURE:
			return {
				...state,
				loggedIn: false,
				appleLoginLoading: false,
				appleLoginFailed: true
			};
		case authConstants.LOGOUT_SUCCESS: {
			removeItem(globalConstants.LOCAL_STORAGE_USER);
			return {
				...state,
				loggedIn: false,
				authType: '',
				googleSignUpMessage: '',
				deletedUserAccount: false
			};
		}
		case authConstants.SIGNUP_REQUEST:
			return {
				...state,
				signUpLoading: true,
				signUpSuccessfully: false,
				signUpFailed: false,
				googleSignUpMessage: ''
			};
		case authConstants.SIGNUP_SUCCESS:
			return {
				...state,
				signUpLoading: false,
				signUpSuccessfully: true
			};
		case authConstants.SIGNUP_FAILURE:
			return {
				...state,
				signUpLoading: false,
				signUpFailed: true
			};
		//signUpGoogle
		case authConstants.SIGNUP_GOOGLE_REQUEST:
			return {
				...state,
				signUpLoading: true,
				signUpSuccessfully: false,
				signUpFailed: false,
				googleSignUpMessage: '',
				googleSignUpResponse: ''
			};
		case authConstants.SIGNUP_GOOGLE_SUCCESS:
			return {
				...state,
				signUpLoading: false,
				signUpSuccessfully: true,
				googleSignUpMessage: action.message,
				googleSignUpResponse: action.response
			};
		case authConstants.SIGNUP_GOOGLE_FAILURE:
			return {
				...state,
				signUpLoading: false,
				signUpFailed: true,
				googleSignUpMessage: '',
				googleSignUpResponse: ''
			};
		//signUpApple
		case authConstants.SIGNUP_APPLE_REQUEST:
			return {
				...state,
				signUpLoading: true,
				signUpSuccessfully: false,
				signUpFailed: false,
				googleSignUpMessage: '',
				googleSignUpResponse: ''
			};
		case authConstants.SIGNUP_APPLE_SUCCESS:
			return {
				...state,
				signUpLoading: false,
				signUpSuccessfully: true,
				appleSignUpMessage: action.message,
				appleSignUpResponse: action.response
			};
		case authConstants.SIGNUP_APPLE_FAILURE:
			return {
				...state,
				signUpLoading: false,
				signUpFailed: true,
				appleSignUpMessage: '',
				appleSignUpResponse: ''
			};
		case authConstants.RESET_SIGNUP_SUCCESS:
			return {
				...state,
				signUpSuccessfully: false
			};
		case workerConstants.SET_WORKER_SUCCESS: {
			const currentToken = getItem(globalConstants.LOCAL_STORAGE_USER).token;
			setItem(globalConstants.LOCAL_STORAGE_USER, {
				userAttributes: action.result[0],
				token: currentToken,
				expirationDate: action.result.tokenExpire * 1000
			});
			return {
				...state,
				userAttributes: action.result[0]
			};
		}
		case workerConstants.UPDATE_USER_ATTRIBUTES:
			const currentUser = getItem(globalConstants.LOCAL_STORAGE_USER);
			let newUserAttributes;
			if (action.userData) {
				const { dateOfBirth, image, images, services, sex, userDescription, userPhoneNumber, userName } = action.data;
				newUserAttributes = {
					...state.userAttributes,
					dateOfBirth: dateOfBirth,
					image: image,
					images: images,
					services: services,
					sex: sex,
					userDescription: userDescription,
					userPhoneNumber: userPhoneNumber,
					userName: userName
				};
			} else if (action.companyChanged) {
				newUserAttributes = {
					...state.userAttributes,
					company: action.data.find((c) => c.userStatus === 'active'),
					companies: action.data
				};
			} else {
				newUserAttributes = {
					...state.userAttributes,
					company: { ...action.data, value: action.data.id, label: action.data.companyName },
					companies:
						state.userAttributes.companies.length === 0
							? state.userAttributes.companies.concat({ ...action.data })
							: state.userAttributes.companies
				};
			}
			currentUser.userAttributes = newUserAttributes;
			setItem(globalConstants.LOCAL_STORAGE_USER, currentUser);
			return {
				...state,
				userAttributes: newUserAttributes
			};
		case authConstants.RESET_PASSWORD_REQUEST:
			return {
				...state,
				resetingPasswordLoading: true,
				passwordReset: false
			};
		case authConstants.RESET_PASSWORD_SUCCESS:
			return {
				...state,
				resetingPasswordLoading: false,
				passwordReset: true
			};
		case authConstants.RESET_PASSWORD_FAILURE:
			return {
				...state,
				resetingPasswordLoading: false,
				passwordReset: false
			};
		case authConstants.RESET_PASSWORD_STATE:
			return {
				...state,
				passwordReset: false
			};
		case workerConstants.SAVE_NOFITICATION_PARAM_SUCCESS: {
			const currentToken = getItem(globalConstants.LOCAL_STORAGE_USER).token;
			setItem(globalConstants.LOCAL_STORAGE_USER, {
				userAttributes: action.result,
				token: currentToken,
				expirationDate: action.result.tokenExpire * 1000
			});
			return {
				...state,
				userAttributes: action.result,
				roleName: action.result && action.result.role && action.result.role.roleName
			};
		}
		case companyConstants.GET_COMPANIES_BY_USER_SUCCESS: {
			const currentUser = getItem(globalConstants.LOCAL_STORAGE_USER);
			const result = action.result;
			const activeCompany = result.find((com) => com.userStatus === 'active');
			const newUserAttributes = {
				...state.userAttributes,
				company: activeCompany,
				companies: result
			};

			currentUser.userAttributes = newUserAttributes;
			setItem(globalConstants.LOCAL_STORAGE_USER, currentUser);
			return {
				...state,
				userAttributes: newUserAttributes
			};
		}
		case workerConstants.AUTOMATIC_REFRESH_WORKING_TIME_SUCCESS: {
			if (state.userAttributes.id === action.result.id) {
				const currentToken = getItem(globalConstants.LOCAL_STORAGE_USER).token;
				setItem(globalConstants.LOCAL_STORAGE_USER, {
					userAttributes: action.result,
					token: currentToken,
					expirationDate: action.result.tokenExpire * 1000
				});
				return {
					...state,
					userAttributes: action.result,
					roleName: action.result && action.result.role && action.result.role.roleName
				};
			}
			return {
				...state
			};
		}
		case authConstants.VERIFY_ACCOUNT_REQUEST:
			return {
				...state,
				accountVerifying: true,
				accountVerified: false,
				accountVerifyMessageFailed: ''
			};
		case authConstants.VERIFY_ACCOUNT_SUCCESS:
			return {
				...state,
				accountVerifying: false,
				accountVerified: true
			};
		case authConstants.VERIFY_ACCOUNT_FAILURE:
			return {
				...state,
				accountVerifying: false,
				accountVerified: false,
				accountVerifyMessageFailed: action.message
			};
		case authConstants.GET_TERMS_FILE_REQUEST:
			return {
				...state,
				getFileLoading: true,
				termsFile: '',
				termsFileFailed: false,
				version: ''
			};
		case authConstants.GET_TERMS_FILE_SUCCESS:
			return {
				...state,
				getFileLoading: false,
				termsFile: action.file,
				version: action.version
			};
		case authConstants.GET_TERMS_FILE_FAILURE:
			return {
				...state,
				getFileLoading: false,
				termsFileFailed: true,
				version: ''
			};
		case authConstants.ACCEPT_TERMS_REQUEST:
			return {
				...state,
				acceptTermsLoading: true,
				acceptTermsFailed: false,
				termsAccepted: false
			};
		case authConstants.ACCEPT_TERMS_SUCCESS:
			const localStorageUser = getItem(globalConstants.LOCAL_STORAGE_USER);
			localStorageUser.userAttributes['termsOfUseAccepted'] = true;
			setItem(globalConstants.LOCAL_STORAGE_USER, localStorageUser);
			return {
				...state,
				acceptTermsLoading: false,
				termsAccepted: true,
				termsAcceptedMessage: action.message
			};
		case authConstants.ACCEPT_TERMS_FAILURE:
			return {
				...state,
				acceptTermsLoading: false,
				acceptTermsFailed: true,
				termsAccepted: false
			};
		case authConstants.UPGRADE_ROLE_REQUEST:
			return {
				...state,
				upgradeRoleLoading: true,
				upgradeRoleFailed: false
			};
		case authConstants.UPGRADE_ROLE_SUCCESS:
			const newUserAttr = {
				...state.userAttributes,
				role: action.result && action.result.role
			};
			const currentToken = getItem(globalConstants.LOCAL_STORAGE_USER).token;
			setItem(globalConstants.LOCAL_STORAGE_USER, {
				userAttributes: newUserAttr,
				token: currentToken,
				expirationDate: newUserAttr.tokenExpire * 1000
			});
			return {
				...state,
				userAttributes: newUserAttr,
				roleName: action.result.role.roleName,
				upgradeRoleLoading: false,
				roleUpgraded: true,
				roleUpgradedMessage: action.message
			};
		case authConstants.UPGRADE_ROLE_FAILURE:
			return {
				...state,
				upgradeRoleLoading: false,
				upgradeRoleFailed: true,
				roleUpgraded: false
			};
		// deleteUserAccount
		case authConstants.DELETE_USER_ACCOUNT_REQUEST:
			return {
				...state,
				deletingUserAccount: true,
				deleteUserAccountFailedMessage: '',
				deletedUserAccount: false
			};
		case authConstants.DELETE_USER_ACCOUNT_SUCCESS:
			return {
				...state,
				deletingUserAccount: false,
				deletedUserAccount: true
			};
		case authConstants.DELETE_USER_ACCOUNT_FAILURE:
			return {
				...state,
				deletingUserAccount: false,
				deleteUserAccountFailedMessage: 'something_went_wrong'
			};
		default:
			return state;
	}
}
